.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    border-bottom: 1px solid #e0e0e0; 
}

.navbar-left {
    flex: 1;
}

.brand-name {
    color: rgb(98, 88, 88);
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold;
}

.nav-links {
    display: flex;
    list-style: none;
    flex: 1;
    justify-content: center;
    gap: 20px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
}

.nav-links a {
    color: rgb(98, 88, 88);
    text-decoration: none;
    font-size: 1.2em;
    padding: 0 15px;
    display: flex;
    align-items: center;
    transition: color 0.2s;
}

.nav-links a:hover {
    color: #65796d; /* Change text color on hover */
}

.navbar-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative; 
}

.nav-icon {
    margin-right: 4.5px;
    margin-left: 4.5px;
    font-size: 9px;
    transform: rotate(9deg);
}

.nav-links a.active .nav-icon {
    text-decoration: none;
}

.logo {
    height: 45px;
    width: auto;
    cursor: pointer;
    transition: transform 0.2s;
}

.logo:hover {
    transform: scale(1.05); 
}

.social-links {
    position: absolute;
    top: 60px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.social-icon {
    width: 25px;
    height: 25px;
    padding: 1.7px;
    transition: transform 0.2s;
}

.social-icon:hover {
    transform: scale(1.1); 
}

.hamburger-icon {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #65796d;
}

.nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #505c55ee;
    z-index: 10;
    display: none;
    flex-direction: column;
    align-items: flex-end; 
    padding: 30px;
    color: white;
    font-family: 'Satoshi';
    font-size: 20px;
    text-align: right;
}

.nav-overlay a {
    text-decoration: none; /* Remove underline */
    color: white; /* Default color */
    transition: color 0.3s ease, transform 0.3s ease;
}

.nav-overlay a:hover {
    font-style: italic;
    text-decoration: underline;
}

.nav-overlay.open {
    display: flex;
}

.nav-overlay li {
    margin: 20px 10px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
}

.close-btn:hover {
    color: white;
}

.pdf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.pdf-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

.pdf-content {
    position: relative;
    background-color: white;
    padding: 10px; 
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 80%; 
    max-width: 700px; 
    height: auto; 
    max-height: 100%; 
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; 
}

.pdf-content iframe {
    width: 100%; 
    height: 80vh; 
    max-height: 100%; 
    border: none; 
}

@media (max-width: 768px) {
    .pdf-content {
        width: 90%; 
        max-width: 500px; 
        padding: 5px; 
    }

    .pdf-content iframe {
        height: 60vh; 
    }
}

@media (max-width: 450px) {
    .pdf-content {
        width: 95%; 
        max-width: 400px; 
    }

    .pdf-content iframe {
        height: 50vh; 
    }

    .close-btn {
        font-size: 16px;
    }
}

/* Hamburger Navigation Bar */
@media (max-width: 900px) {
    .nav-links {
        display: none;
    }

    .hamburger-icon {
        display: block;
    }

    .logo {
        height: 0px;
    }

    .social-links {
        display: none; 
    }
}

