/* src/components/ImageTile.css */

.image-tile {
    position: relative; 
    width: 340px;
    height: 300px;
    border: 3px solid rgb(98, 88, 88);
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;
}

.image-tile-title {
    color: rgb(98, 88, 88);
    font-family: 'Satoshi', sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
    margin-top: 6px;
    margin-bottom: 6px;
    width: 100%; 
    white-space: nowrap;
}

.image-tile-desc {
    color: rgb(98, 88, 88);
    font-family: 'Satoshi', sans-serif;
    font-size: 1.2em;
    font-style: italic;
    font-weight: bold;
    text-align: left; 
    margin-top: 10px; 
    margin-bottom: 10px;
    width: 100%; 
}

.image-tile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-tile-icon {
    position: absolute;
    bottom: 10px; 
    right: 10px; 
    font-size: 1.5em;
    color: white;
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 2; 
}

.image-tile-icon:hover {
    transform: scale(1.2); 
}

video.modal-image {
    max-height: 50vh;
    object-fit: cover;
}