/* src/components/ExperienceItem.css */

.title-link {
    color: inherit; /* Keeps the title color */
    text-decoration: none; /* Removes underline */
}

.title-link:hover {
    font-size: 1.05em;
}

.experience-item {
    width: 100%;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    font-family: 'Satoshi', sans-serif;
    background-color: #65796d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.experience-item-details {
    display: flex;
    flex-direction: column;
}

.experience-item-title {
    font-size: 1.8em;
    font-family: 'BespokeSerif', sans-serif;
    font-weight: 500;
    margin: 0;
    color: white;
}

.experience-item-date-role {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.experience-item-role {
    font-weight: bold;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
}

.experience-item-date {
    font-size: 1em;
    color: white;
    margin-top: 5px;
}

.experience-item-button {
    transition: background-color 0.2s;
    margin-top: 5px;
    padding: 8px 18px;
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    color: #eae5e5e8;
    background-color: transparent;
    border: 2px solid #eae5e5e8;
    border-radius: 40px;
    cursor: pointer;
}

.experience-item-content {
    margin-top: 10px;
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #ddd;
}

.experience-item-content h4 {
    margin-top: 0;
}

.experience-item-desc {
    font-size: 1.1em;
}

.skills-list {
    display: flex;
    flex-wrap: wrap; /* Allows skills to wrap to the next line if necessary */
    gap: 10px;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.skill-item {
    background-color: rgb(98, 88, 88);
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.9em;
    font-weight: 500;
    font-family: 'Satoshi', sans-serif;
    text-align: center;
    margin-bottom: 10px;
}
